<template>
<div >
    <div @click="viewProductList(data.item_id)"  id="trend-item" style="cursor: pointer; border: 1px solid #ddd;">
        <div class="row" style="padding:0 0px 0 15px  !important;">
            <div class="col-md-12 col-sm-12"><h4 style="margin-bottom:0px;">{{data.title}}</h4></div>
        </div>
        <div style="border: 0.2px solid #ddd !important; margin: 0 15px 10px 15px !important;"></div>
        <div class="row panel " style="padding:0px; margin:0px;margin-bottom: 10px;" id="trend-item">
            <div class="col-md-8 col-sm-8" style="">
                <img style="    width: 100%;" :src="data.imgpath[0]" class="img-responsive img-right">
            </div>
            <div class="col-md-4 col-sm-4" style="padding-left: 0px;">
                <div class="">
                    <div class=" image-section cell">
                        <img style="width: 100%;" :src="data.imgpath[1]" class="img-responsive img-left">
                    </div>
                    <div class=" image-section cell" style="padding-top:5px ">
                        <img style=" width: 100%;" :src="data.imgpath[2]" class="img-responsive img-left">
                    </div>
                </div>
            </div>
        </div>
    </div>
        
</div>
</template>
<script> 
export default {
name: 'ProductTile',
props: {
    data: {
      type: Object
    },
  },
components: {},
methods: {
    quickViewProductModal: function () {
      //alert('View Product Popup Modal...')
    //   this.$refs.modal.show()
    //  $('#productModalCenter').modal('show');
    },
    viewProductList:function(item_id){ 
        this.$router.push({ path: 'productlist', query: { id: item_id } })
    }
  }


}
</script>
<style scoped>
#trend-item:hover {
  opacity: 0.7;
}
@media screen and (min-width: 1800px) {
    .img-right{
    height: 165px!important;
    }
    .img-left{
        height:calc(160px / 2)!important;
    }
}
.img-right{
    height: 85px;
}
.img-left{
    height:calc(80px / 2);
}
@media screen and (max-width: 764px) {
    .image-section {
        width: auto !important;
        float: left !important;
        padding-top: 0px !important; 
    }

    .image-section img { 
        max-width: 120px !important;
    }
}
.cell {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto; 
}
</style>

