<template>
   <div> 
	   <div id="content" class="col-md-12">
        		<div class="products-category">
                    <h3 class="title-category ">{{brandname}}</h3>
        			<div class="category-desc">
        				<div class="row">
        					<div class="col-sm-12">
        						<div class="banners">
                                    <div ></div>
        							<div class="animated-background">
        								<a v-if="brandcover !== ''" ><img style="width:100%;" src="assets/image/catalog/banners/banner.png" alt="img cate"><br></a>
        							</div>
        						</div>
        					</div>
        				</div>
                        
        			</div>
                    
        			<!-- Filters -->
                    <div class="product-filter product-filter-top filters-panel">
                        <div class="row">
                            <div class="col-md-5 col-sm-3 col-xs-12 view-mode">
                                
                                    <div class="list-view">
                                        <button class="btn btn-default grid " @click.prevent.stop="display('grid')" :class="showmode == 'grid' ? 'active' : ''" data-view="grid" data-toggle="tooltip"  data-original-title="Grid"><i class="fa fa-th"></i></button>
                                        <button class="btn btn-default list" @click.prevent.stop="display('list')" :class="showmode == 'list' ? 'active' : ''" data-view="list" data-toggle="tooltip" data-original-title="List"><i class="fa fa-th-list"></i></button>
                                    </div>
                        
                            </div>
                            <div class="short-by-show form-inline text-right col-md-7 col-sm-9 col-xs-12">
                                <div class="form-group short-by">
                                    <label class="control-label" for="input-sort">Sort By:</label>
                                    <select id="input-sort" class="form-control">
                                        <option value="" selected="selected">Default</option>
                                        <option value="">Name (A - Z)</option>
                                        <option value="">Name (Z - A)</option>
                                        <option value="">Price (Low &gt; High)</option>
                                        <option value="">Price (High &gt; Low)</option>
                                        <option value="">Rating (Highest)</option>
                                        <option value="">Rating (Lowest)</option>
                                        <option value="">Model (A - Z)</option>
                                        <option value="">Model (Z - A)</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="control-label" for="input-limit">Show:</label>
                                    <select id="input-limit" class="form-control" >
                                        <option value="" selected="selected">15</option>
                                        <option value="">25</option>
                                        <option value="">50</option>
                                        <option value="">75</option>
                                        <option value="">100</option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="box-pagination col-md-3 col-sm-4 col-xs-12 text-right">
                                <ul class="pagination">
                                    <li class="active"><span>1</span></li>
                                    <li><a href="">2</a></li><li><a href="">&gt;</a></li>
                                    <li><a href="">&gt;|</a></li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <!-- //end Filters -->

        			<!--changed listings-->
                    <div class="products-list row nopadding-xs so-filter-gird">

                        <!-- <div class="col-md-12" id="preview-area" v-if="blockLoading">
                            <div class="row text-center">
                                <div class="sk-chase">
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                </div>
                            </div>
                        </div> -->

                        <div class="col-md-12"  v-if="!blockLoading && blocks.length === 0"> 
                            <h4>No Items Found</h4>
                        </div>
            	
        				<div  v-for="(item,index) in blocks" :key="index"> 
                            <component  :is="item.blockdatacomponent" v-bind:noslide="true" v-bind:data="item" ></component>
                        </div>
                        
   

                    </div>

                    <div v-if="!stoppagi" class="text-center" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="30">
                            <img src="/assets/image/product/loader.gif" />
                            <h4>LOADING...</h4>
                        </div>
        			<!--// End Changed listings-->
        			<!-- Filters -->
        			<div class="product-filter product-filter-bottom filters-panel">
                        <div class="row">
                            <div class="col-sm-6 text-left"></div>
                            <div class="col-sm-6 text-right">Showing 1 to 15 of 15 (1 Pages)</div>
                        </div>
                    </div>
        			<!-- //end Filters -->
        			
        		</div>
        		
        	</div>

            <!-- Modal -->
            <div class="modal fade" id="productModalCenter" tabindex="-1" role="dialog" aria-labelledby="productModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                    <div class="modal-header" style="display: flex; justify-content: space-between; align-items: end;">
                        <h5 class="modal-title" id="productModalLongTitle">Product Quick View</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="margin-top:-20px;">
                        <span  style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <ProductModalContent v-bind:pid="this.product_id" />

                    </div>
                    <div class="modal-footer">
                        <button style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;" type="button" class="btn" data-dismiss="modal">Close</button>
                        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                    </div>
                    </div>
                </div>
            </div> 
   </div>
</template>
<script>
import ProductTile from '@/components/product/product_tile.vue'
import ProductTileBrand from '@/components/product/product_tile_brand.vue'
import ProductTileGrid from '@/components/product/product_tile_grid.vue'

import ProductModalContent from '@/components/product/product_modal.vue';

import mixinProduct from "@/mixins/buyer/APIProduct";

export default {
    components: { 
        ProductTile,
        ProductTileBrand,
        ProductTileGrid,
        ProductModalContent
    },
    mixins: [mixinProduct], 
    data() {
        return {
            breadcrumb: [
                {
                text: "Product List",
                href: "/productlist",
                active: false
                }
            ],
            cID: '',
            bID: '',
            product_id: '',
            blocks: [ 
            ], 
            showmode: 'list',
            blockLoading: true,
            brandlogo: "https://cdn.dribbble.com/users/261302/screenshots/1361907/logoplaceholder.png",
            brandcover:"",
            brandname: '',
            busy: false,
            pagicount: 1,
            stoppagi: false
        }
    },
    watch: {
        $route(to, from) {
            this.$forceUpdate();
                this.callFucntionsByOrder();
        },
        '$route.query.id'() {
        
            this.$forceUpdate();
            this.callFucntionsByOrder();
        },
        '$store.state.filterdata': function() {
            this.getProductsByID();
        }
    },
    mounted: function() {
        
        this.handleBreadcrumb();

        this.display('grid'); 
    
    },
    created(){
        this.initiateList();
        this.callFucntionsByOrder(); 
        // this.handleGetStores();
    },
    methods: {
        getURLcID(){
            let url = window.location.href.split("?id=");
            let idarray = url[1].split("&bid=")
            // console.log(url[1]);
            this.cID = idarray[0];   
            this.bID = idarray[1]; 
            return idarray[0];
        },
        callFucntionsByOrder: async function() {  
            localStorage.setItem('filterdata', null);  

      		this.$store.dispatch("fetchFilterData");    
            this.getURLcID() 
            // await this.getProductsByID();
        },
        handleBreadcrumb: function() {		
            this.$store.commit("setBreadcrumbSwitchState", {
                action: null,
                payload: this.breadcrumb
            });
        },
        getProductsByID: async function(currentpage) {

            this.blockLoading = true;
            try {  
                // this.blocks = [];
                let params = "category=" + this.cID;
                params += "&brands="+ this.bID; 
                params += "&page="+ this.pagicount;
                params += "&perpage="+ 30;
                
                if(this.$store.state.filterdata != null){
                    if(this.$store.state.filterdata.brands.length > 0){
                        for (let index = 0; index < this.$store.state.filterdata.brands.length; index++) {
                            params += "&brands="+ this.$store.state.filterdata.brands[index];                            
                        }
                    }

                    if(this.$store.state.filterdata.attributes.length > 0){
                        for (let index = 0; index < this.$store.state.filterdata.attributes.length; index++) {
                            params += "&attribute_values="+ this.$store.state.filterdata.attributes[index];                            
                        }
                    }

                    if(this.$store.state.filterdata.searchtext !=  ''){
                            params += "&name="+ this.$store.state.filterdata.searchtext; 
                    }
                    
                    // console.log(this.$store.state.filterdata); 
                }

                // console.log(params); 
                let productlist = await this.getProductListByCIDFilters(params);
                // console.log(productlist); 
                let blockdata = [];
                for (let index = 0; index < productlist.length; index++) {
                    
                    if(index === 0){
                        // this.brandcover = productlist[index].brand.cover_img[0];
                        this.brandcover = "assets/image/catalog/demo/category/img-cate.jpg";
                        this.brandname = productlist[index].brand.brand_name;
                    }

                    let imgs = [];
                    let imagearray = productlist[index].show_image;
                    for (let index2 = 0; index2 < imagearray.length; index2++) {
                        let imgpath = imagearray[index2].image;
                        // let is1 = imgpath.split("upload/")[0];
                        // let is4 = imgpath.split("upload/")[1];
                        // let is2 = "upload/q_auto,f_auto/w_300,h_300,c_fill/";
                        // let is3 = is4.split("/")[1];
                        // imgpath = is1 + is2 + is4; 
                        imgs.push(imgpath);
                    }

                    let oldprice = '';
                    let newprice = '';
                    if(productlist[index].simple_products[0].special_price != 0 && productlist[index].simple_products[0].special_price != ''){
                        newprice = productlist[index].simple_products[0].special_price;
                        oldprice = productlist[index].simple_products[0].price;
                    } else {
                        newprice = productlist[index].simple_products[0].price;
                        oldprice = '';
                    }
                    // console.log(productlist[index]._id);
                    blockdata.push({
                        blockdatacomponent: "ProductTile",
                        discountprecentage: '',
                        imgpath: imgs,
                        item_id: productlist[index]._id,
                        prediscount: oldprice,
                        price: newprice,
                        wishlit: productlist[index].wishlit,
                        product_name: productlist[index].product_name,
                        primary_product: productlist[index].primary_product,
                        rating: productlist[index].rate,
                        title: productlist[index].product_name,
                        url: "/product/"+productlist[index]._id
                    });
                    
                    
                }        
                // console.log(blockdata);
                // this.blocks = blockdata;
                blockdata.forEach(element => {
                    this.blocks.push(element); 
                });
                this.blockLoading = false
                
            } catch (error) {
                this.blockLoading = false;
            }
        
        }, 
        initiateList(){
            let self = this;
            $('.list-view .btn').each(function() {
                
                    $(this).siblings('.btn').removeClass('active');
                
            });
        },
        display(view) {
            $('.products-list').removeClass('list grid').addClass(view);
            $('.list-view .btn').removeClass('active');
            if(view == 'list') {
                //$('.products-list .product-layout').addClass('col-lg-12');
                // $('.products-list .product-layout .left-block').addClass('col-md-4');
                // $('.products-list .product-layout .right-block').addClass('col-md-8');
                $('.products-list .product-layout .item-desc').removeClass('hidden');
                $('.products-list .product-layout .list-block').removeClass('hidden');
                $('.products-list .product-layout .button-group').addClass('hidden');
                $('.list-view .' + view).addClass('active');
                $('.list-view .btn').removeClass('active');
                $.cookie('display', 'list'); 
            }else{
                //$('.products-list .product-layout').removeClass('col-lg-12');
                //$('.products-list .product-layout .left-block').removeClass('col-md-4');
                //$('.products-list .product-layout .right-block').removeClass('col-md-8');
                $('.products-list .product-layout .item-desc').addClass('hidden');
                $('.products-list .product-layout .list-block').addClass('hidden');
                $('.products-list .product-layout .button-group').removeClass('hidden');
                $('.list-view .' + view).addClass('active');
                $('.list-view .btn').removeClass('active');
                $.cookie('display', 'grid');
            }

            
            this.showmode = view
        
        },
        pushToProduct()	{
            this.$router.push('/product')
        },
        parentModalTrigger(pid) {
            this.product_id = pid; 
                

            this.inputNumValue = 1;
            this.isDisable = true;
            this.idObj = {
                ids: []
            };

        //    this.setProductInfo(); 
            $('#productModalCenter').modal('show');
        },
        loadMore: async function(currentpage) { 
            if(!this.stoppagi){
                this.busy = true;
                
                await this.getProductsByID(this.pagicount);
                let datacount = this.pagicount * 30
                if(this.blocks.length === datacount){
                    this.stoppagi = false;
                } else {
                    this.stoppagi = true;
                }          
                    
                this.pagicount++;
                this.busy = false;
            } 
        }
    }  
}
</script>
<style >
.mb-0{margin-bottom:0px !important;}
.mb-5{margin-bottom:5px !important;}
.m0p0{margin:0px !important; padding:0px !important;}

#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 4680px 0
    }
}

.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 4800px 104px; 
    position: relative;
    min-height: 250px;
}
.filters-panel .list-view button.btn.active{
    background: #ffd338;
}
</style>