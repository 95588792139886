<template>
  <div class="prdbrnsitems-block">
    <div class="item">
      <a @click="haddleBrands(data)">
        <img
          v-if="data.image"
          :src="data.image"
          class="img-responsive img"
          style="height: 180px; width: 180px;"
          alt="brand"
        />
        <h4 style="font-size: x-large;" v-if="!data.image" class="brndname-for-noneimage">{{ data.brand_name }}</h4>
      </a>
    </div>
  </div>
</template>
<script>
import APIProduct from "@/mixins/buyer/APIProduct";

export default {
  name: "ProductTile",
  mixins: [APIProduct],
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    haddleBrands: function(obj) {
      this.$router.push({ name: 'Brand Search', query: { id:  obj._id, name: obj.brand_name } })
    }
  }
};
</script>
<style scoped>
.brndname-for-noneimage{
  display: flex;
  flex-wrap: wrap;
  width: 180px;
  height: 180px;
  align-content: center;
  justify-content: center;
  color: #5779ae;
  background: rgb(224, 235, 235);
  margin: 5px auto 5px auto;
}
.prdbrnsitems-block .item{
  opacity: 1 !important;
}
.prdbrnsitems-block .item img{
  margin-top: 5px;
  margin-bottom: 5px;
  /* outline: 1px solid red; */
}
.prdbrnsitems-block .item img:hover{
  -webkit-box-shadow: 0px 0px 8px 0px rgba(87,121,174,0.45); 
  box-shadow: 0px 0px 8px 0px rgba(87,121,174,0.45);
}
.prdbrnsitems-block .item h4:hover{
  -webkit-box-shadow: 0px 0px 8px 0px rgba(87,121,174,0.45); 
  box-shadow: 0px 0px 8px 0px rgba(87,121,174,0.45);
}

/* Media queries */
@media screen and (max-width: 768px) {
  .brndname-for-noneimage{
    font-size: 16px !important;
  }

}
@media screen and (max-width: 425px) {
  .brndname-for-noneimage{
    font-size: 14px !important;
  }

}
</style>
